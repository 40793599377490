/* @import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap"); */
body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../assets/font/PlusJakartaSans-VariableFont_wght.ttf')
    format('truetype');
  font-weight: 200 300 400 500 600 700 800 900;
  font-display: swap;
  font-style: normal;
}
html {
  font-size: 62.5%;
}
@media only screen and (max-width: 1440px) {
  html {
    font-size: 50% !important;
  }
}
@media only screen and (max-width: 1280px) {
  html {
    font-size: 45% !important;
  }
}
@media only screen and (max-width: 1024px) {
  html {
    font-size: 40% !important;
  }
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 35% !important;
  }
}
@media only screen and (max-width: 767px) {
  .card {
    flex-direction: column;
  }
  .tag-text {
    font-size: 5rem !important;
  }
  .bottom-line {
    flex-direction: column;
  }
  .desc {
    font-size: 3rem !important;
  }
  .content-text,
  .card-content {
    max-width: 100% !important;
  }
  .bottom-line .text,
  .bottom-btn {
    font-size: 3.2rem !important;
  }
}
@media only screen and (max-width: 500px) {
  html {
    font-size: 30% !important;
  }
  .content {
    margin-bottom: 10rem;
    padding-top: 5rem !important;
  }
  .logos {
    transform: scale(0.8);
    left: -9rem !important;
    bottom: -8rem !important;
  }
  .logo1 span,
  .logo2 span {
    left: -1rem !important;
    font-size: 2.3rem !important;
  }
  .tag-text {
    font-size: 3rem !important;
  }
  .card {
    width: 85vw !important;
  }
  .tag {
    width: 85vw !important;
    padding: 0 !important;
    justify-content: center !important;
  }
  .buttons button{
    margin-right: 0 !important;
  }
  .bottom-btn{
    margin-left: 0 !important;
  }
  .image {
    margin-left: 0 !important;
  }
  .tag-text{
    text-align: center;
  }
  .card {
    padding: 6.7rem 4rem 6.7rem 4rem !important;
  }
  
  .btn {
    width: 100%;
    min-width: 20rem !important;
    padding: 2rem 2rem !important;
    line-height: 100% !important;
  }
  .text {
    text-align: center;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
main {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.max-1440 {
  background: url(../assets/images/bg.png) no-repeat top center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fbc943;
  background-blend-mode: multiply;
  overflow: hidden;
  /* max-width: 1440px; */
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

.tag {
  display: flex;
  max-width: 128rem;
  /* height: 14rem; */
  /* gap: 2.5rem; */
  align-items: center;
  padding: 2rem 3.3rem 2rem 5.4rem;
  background-color: #263766;
  border-radius: 100px;
  /* border-top: 2px solid #ffffff;
  border-bottom: 2px solid rgba(255, 255, 255, 0); */
  margin-bottom: 4.5rem;
  /* border-image-source: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  ); */
}
.tag-text {
  font-weight: 700;
  font-size: 7.681rem;
  line-height: 117.74%;
  color: #fff;
}
.tag > img {
  width: 9.4rem;
}
.card {
  position: relative;
  display: flex;
  max-width: 144rem;
  width: 100%;
  /* gap: 6rem; */
  margin-top: 5rem;
  background-color: #fff;
  border-radius: 4.5rem;
  margin-bottom: 4.5rem;
}
.card:first-child {
  padding: 6.7rem 9.2rem 6.7rem 5.4rem;
}
.card:nth-child(2) {
  padding: 6.7rem 5rem 6.7rem 5.4rem;
}
.card .image {
  /* max-width: 50rem; */
  flex: 1;
  margin-left: 6rem;
}

.image img {
  width: 100%;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 68rem;
  padding: 1.2rem 0;
  /* gap: 2rem; */
  /* width: 100%; */
}
.content-text {
  font-weight: 600;
  font-size: 4.5rem;
  line-height: 117.74%;
  letter-spacing: -0.01em;
  max-width: 65rem;
}
.text-blue {
  color: #263766;
}
.text-green {
  color: #139036;
}
@media (min-width: 769px) {
  .blue-hover:hover {
    background-color: #273866;
    color: #fff;
    border: none;
  }
  .green-hover:hover {
    background-color: #139036;
    color: #fff;
    border: none;
  }
  .skyblue-hover:hover {
    background-color: #1690d4;
    color: #fff;
    border: none;
  }
  .pink-hover:hover {
    background-color: #d4106b;
    color: #fff;
    border: none;
  }
}

@media (max-width: 768px) {
  .blue-hover:active {
    background-color: #273866;
    color: #fff;
    border: none;
  }
  .green-hover:active {
    background-color: #139036;
    color: #fff;
    border: none;
  }
  .skyblue-hover:active {
    background-color: #1690d4;
    color: #fff;
    border: none;
  }
  .pink-hover:active {
    background-color: #d4106b;
    color: #fff;
    border: none;
  }
}

.bg-green {
  background-color: #139036 !important;
  color: #fff !important;
  border: none !important;
}
.bg-blue {
  background-color: #273866 !important;
  color: #fff !important;
  border: none !important;
}
.text-skyblue {
  color: #1690d4;
}
.bg-skyblue {
  background-color: #1690d4 !important;
  color: #fff !important;
  border: none !important;
}

.text-pink {
  color: #d4106b;
}
.bg-pink 
/* ,.bottom-btn:active */ {
  background-color: #d4106b;
  color: #fff;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  /* gap: 1.26rem; */
  align-items: center;
  margin-top: 3rem;
}
.buttons .btn {
  margin-right: 2rem;
  margin-bottom: 2rem;
}
.btn {
  padding: 2rem 4rem;
  min-width: 28.8rem;
  /* height: 8.657rem; */
  border-radius: 8.2063rem;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 3.15627rem;
  line-height: 4.1rem;
  text-align: center;
  letter-spacing: -0.02em;
  /* text-transform: capitalize; */
}
.border-btn {
  border: 0.220358rem solid #706864;
  color: #706864;
  background-color: #fff;
}
.card .mark {
  position: absolute;
  left: 5.4rem;
  top: -5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11.148rem;
  height: 11.148rem;
  border-radius: 100%;
  font-weight: 700;
  font-size: 6.62025rem;
  line-height: 86px;
}
.mark-color1 {
  color: #273766;
  background-color: #eceff8;
}
.mark-color2 {
  color: #139036;
  background-color: #e8fcee;
}
.mark-color3 {
  color: #1690d4;
  background-color: #e8f5fd;
}
.mark-color4 {
  color: #d4106b;
  background-color: #faeef3;
}
.bottom-line {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 10rem; */
}
.bottom-line .text {
  max-width: 70.412rem;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 117.74%;
  letter-spacing: -0.01em;
  color: #273766;
}
.desc {
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
  font-size: 1.6rem;
  line-height: 172.2%;
  letter-spacing: -0.01em;
  color: #273766;
  word-wrap: break-word;
}
.desc p {
  margin-top: 1.5rem;
}
.texts {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.bottom-btn {
  display: flex;
  gap: 1rem;
  height: 8.34rem;
  align-items: center;
  font-weight: 400;
  font-size: 2.36938rem;
  line-height: 3.1rem;
  color: #263766;
  padding: 3rem 5.2rem 3rem 4.9rem;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5rem;
  margin-left: 3rem;
  margin-top: 2rem;
}
.bottom-btn img {
  margin-left: 1rem;
}
.pink-text-bg {
  font-weight: 900;
  border-radius: 0.2rem;
  padding: 0.3rem;
  color: #d4106b;
  background-color: #fde7f1;
  text-decoration: none;
}
.over {
  width: 2.343rem;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 4.4rem; */
  width: 100%;
  padding: 11.5rem 6rem 26rem 6rem;
}
.logos {
  position: absolute;
  bottom: -6rem;
  left: -7rem;
  background: #fff;
  width: 35.5rem;
  height: 35.5rem;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  padding-right: 8rem;
  justify-content: stretch;
  align-items: flex-end;
  border-radius: 100%;
  overflow: hidden;
  border: 4px solid rgba(0, 0, 0, 0.3);
}
.logo1,
.logo2 {
  display: flex;
  position: relative;
  width: 15rem;
}
.logo1 span,
.logo2 span {
  position: absolute;
  top: 42%;
  left: -1.3rem;
  font-size: 1.7rem;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  color: grey;
  margin-left: -4rem;
}
.logo1 span {
  left: -2rem;
}
.logo1 img,
.logo2 img {
  width: 15.4rem;
  max-height: 17rem;
}
.logo2 img {
  width: 15rem;
}
